(function() {
    'use strict';

    angular.module('common')
        .component('aflLogin', {
            templateUrl:'static/javascript/directives/afl-login/afl-login.html',
            controller: LoginController,
            bindings: {
                email: '=',
                password: '=',
                role: '=',
                forcedLogin: '=',
                hasExistingSession: '=',
                onSubmit: '&',
                sendEmail: '&'

            }
        });

    function LoginController($scope, $element, $attrs) {
        var $ctrl = this;

        $ctrl.forcedLoginProceed = function forcedLoginProceed() {
            $ctrl.forcedLogin = true;
            $ctrl.onSubmit({
                "email": $ctrl.email,
                "password": $ctrl.password,
                "forcedLogin": $ctrl.forcedLogin
            });
        };

        $ctrl.forcedLoginDecline = function forcedLoginDecline() {
            $ctrl.hasExistingSession = false;
        };
    }
})();
